import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';
import { useLocation } from '@reach/router';
import Helmet from 'react-helmet';

const Footer = ({
                  row,
                  col,
                  colOne,
                  colTwo,
                  titleStyle,
                  logoStyle,
                  textStyle
                }) => {
  const Data = useStaticQuery(graphql`
      query {
          saasModernJson {
              FOOTER_WIDGET {
                  title
                  menuItems {
                      text
                      url
                  }
              }
          }
      }
  `);
  const location = useLocation();
  const [showChat, setShowChat] = React.useState(false);
  const [loadingChat, setLoadingChat] = React.useState(false);
  const openChat = () => {
    setLoadingChat(true);
    setShowChat(true);
    setTimeout(() => {
      setLoadingChat(false);
      hideTdo();
      setInterval(hideTdo, 10);
    }, 3000);
  };
  return (
    <>
      <FooterWrapper>
        <Container className="footer_container">
          <Box className="row" {...row}>
            <Box {...colOne}>
              <a
                style={{ color: 'black', fontWeight: 500 }}
                className="logo-alt"
                href="https://b2bsaasleads.com"
              >
                B2B SaaS Leads
              </a>
              <Text content="hello@b2bsaasleads.com" {...textStyle} />
              <a
                href="https://www.producthunt.com/posts/b2b-saas-leads?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-b2b-saas-leads"
                target="_blank"><img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=246756&theme=light"
                alt="B2B SaaS Leads - Get a list of potential customers for your SaaS in 60 secs | Product Hunt"
                style={{ width: '220px', height: '54px', marginTop: '20px' }} width="220"
                height="54"/></a>
            </Box>
            {/* End of footer logo column */}
            <Box {...colTwo}>
              {Data.saasModernJson.FOOTER_WIDGET.map((widget, index) => (
                <Box className="col" {...col} key={`footer-widget-${index}`}>
                  <Heading content={widget.title} {...titleStyle} />
                  <List>
                    {widget.menuItems.map((item, index) =>
                      <ListItem key={`footer-list-item-${index}`}>
                        {item.url === 'chat' ?
                          <a onClick={loadingChat ? null : openChat} className="ListItem"
                             style={{ cursor: 'pointer' }}>
                            {loadingChat ? 'Loading..' : item.text}
                          </a>
                          : <a href={item.url} className="ListItem">
                            {item.text}
                          </a>}
                      </ListItem>)}
                  </List>
                </Box>
              ))}
            </Box>
            {/* End of footer List column */}
            <Box style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <a
                style={{ color: '#333', fontSize: '14px' }}
                className="copyRightText"
                href="https://unitylabs.ai/?ref=b2bsaasleads"
              >
                Powered by Unity{' '}
                <small>
                  <sup>Ⓒ</sup>
                </small>
              </a>
            </Box>
          </Box>
        </Container>
      </FooterWrapper>
      {showChat && <Helmet>
        <script src="//code.tidio.co/xx7hinbzw7el0ymwhpyr7mf3jpuw5gq8.js" async/>
      </Helmet>}
    </>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object
};

function hideTdo() {
  let timer = null;
  const target = document.querySelector('#tidio-chat iframe');
  if (!target) {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(hideTdo, 500);
  } else {
    let timer2 = null;
    const tdo = document.querySelector('#tidio-chat iframe')
      .contentDocument
      .querySelector('a[href*="tidio.com/powered"]');
    if (!tdo) {
      if (timer2 !== null) {
        clearTimeout(timer2);
      }
      timer2 = setTimeout(hideTdo, 1);
      return;
    }
    document.querySelector('#tidio-chat iframe')
      .contentDocument
      .querySelector('a[href*="tidio.com/powered"]')
      .remove();
    return true;
  }
}

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // Footer col one style
  colOne: {
    width: [1, '35%', '35%', '23%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0]
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '65%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap'
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
    mb: '30px'
  },
  // Default logo size
  logoStyle: {
    width: '130px',
    mb: '15px'
  },
  // widget text default style
  textStyle: {
    color: '#0f2137',
    fontSize: '16px',
    mb: '10px',
    mt: '10px'
  }
};

export default Footer;
